@font-face {
  font-family: "Gantari-Regular";
  src: local("Gantari-Regular"),
    url("./fonts/Gantari-Regular.ttf") format("truetype"); /*url("../fonts/Graphik-Regular.woff") format("woff");*/
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Gantari-Italic";
  src: local("Gantari-Italic"),
    url("./fonts/Gantari-Italic.ttf") format("truetype"); /*url("../fonts/Graphik-Medium.woff") format("woff");*/
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Gantari-Bold";
  src: local("Gantari-Bold"), url("./fonts/Gantari-Bold.ttf") format("truetype"); /*url("../fonts/Graphik-Medium.woff") format("woff");*/
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Gantari-SemiBold";
  src: local("Gantari-SemiBold"),
    url("./fonts/Gantari-SemiBold.ttf") format("truetype"); /*url("../fonts/Graphik-Medium.woff") format("woff");*/
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
